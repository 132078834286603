import {lazy} from "react";
import NavigationView from "Navigation/NavigationView.js";

export default [
	{
		uris: [
			"/assets/:asset/:tab?",
			"/asset_detail.php"
		],
		component: lazy(() => import("Assets/AssetView.js")),
		title: "Asset Details"
	},
	{
		uris: [
			"/comms/:folder?",
			"/comms/messages/:messageId/acknowledgements",
			"/comms/messages/:messageId",
			"/comms/draft/:composerMessageId",
			"/comms/reply/:composerMessageId",
			"/comms/forward/:composerMessageId"
		],
		component: lazy(() => import("Comms/CommsMessagesView.js")),
		title: "HOPS-Comms",
		permission: [130, 0]
	},
	{
		uri: "/f12/:tab",
		component: lazy(() => import("F12/F12View.js")),
		title: "F12"
	},
	{
		uri: "/f12",
		component: lazy(() => import("F12/F12View.js")),
		public: true,
		title: "F12"
	},
	{
		uris: ["/id_cards/*", "/id_cards"],
		component: lazy(() => import("IdCards/IdCardsView.js")),
		title: "HOPS Cards"
	},
	{
		uri: "/download/:file",
		component: lazy(() => import("Library/LibraryDownloadView.js")),
		title: "File Download"
	},
	{
		uri: "/login",
		component: lazy(() => import("Login/LoginView.js")),
		public: true,
		publicOnly: true,
		title: "Login"
	},
	{
		uris: ["/login.php", "/access_denied.php"],
		component: lazy(() => import("Login/LoginViewLegacy.js")),
		public: true,
		publicOnly: true,
		title: "Login"
	},
	{
		uris: ["/memberships/:Id/edit", "/memberships/:Id/renew"],
		component: lazy(() => import("Memberships/MembershipRecordView/MembershipRecordView.js")),
		title: "Membership Subscription Record",
		permission: [194, 0]
	},
	{
		uri: "/memberships/record",
		component: lazy(() => import("Memberships/MembershipRecordView/MembershipRecordView.js")),
		title: "New Membership Subscription Record",
		permission: [194, 0]
	},
	{
		uri: "/memberships/orders/:OrderItemId/confirm",
		component: lazy(() => import("Memberships/MembershipOrderConfirmation/MembershipOrderConfirmationView.js")),
		title: "Membership Sale"
	},
	{
		uri: "/ms_auth/:org?",
		component: lazy(() => import("OpenId/OpenIdMicrosoft.js")),
		noLogin: true,
		public: true,
		title: "Microsoft Login"
	},
	{
		uris: ["/news/members"],
		component: lazy(() => import("News/NewsView.js")),
		title: "Members News"
	},
	{
		uris: ["/news", "/news.php"],
		component: lazy(() => import("News/NewsView.js")),
		title: "News"
	},
	{
		uris: ["/news/:id/edit", "/news/create", "/news_story_edit.php"],
		component: lazy(() => import("News/NewsStoryEditorView.js")),
		title: "News"
	},
	{
		uris: ["/news/:id", "/news_story.php"],
		component: lazy(() => import("News/NewsStoryView.js")),
		title: "News"
	},
	{
		uri: "/navigate",
		component: NavigationView,
		title: "Please wait..."
	},
	{
		uri: "/openid/microsoft",
		component: lazy(() => import("OpenId/OpenIdMicrosoft.js")),
		noLogin: true,
		public: true,
		publicOnly: true,
		title: "Microsoft Login"
	},
	{
		uri: "/sales/:OrderId/moto",
		component: lazy(() => import("Orders/Payments/SettleOrderPaymentView.js")),
		permission: [467, 0]
	},
	{
		uri: "/sales/:OrderId/refund",
		component: lazy(() => import("Orders/Payments/SettleOrderPaymentView.js")),
		permission: [522, 0]
	},
	{
		uri: "/pwr/:token",
		component: lazy(() => import("Login/PwrView.js")),
		noLogin: true,
		public: true,
		title: "Password Reset"
	},
	{
		uri: "/elearning/:course",
		component: lazy(() => import("Elearning/ElearningView.js")),
		title: "Elearning",
		hideWhenAdminAuthed: true
	},
	{
		uris: ["/user_roster", "/user_roster.php"],
		component: lazy(() => import("Roster/RosterView.js")),
		title: "Individual Roster"
	},
	{
		uri: "/raildays/cards",
		component: lazy(() => import("Ui/Raildays/RaildaysIdCardsView.js")),
		title: "Raildays"
	},
	{
		uri: "/raildays/chat/:correspondent",
		component: lazy(() => import("Ui/Raildays/RaildaysChatConversationView.js")),
		title: "Raildays",
		hideWhenAdminAuthed: true
	},
	{
		uri: "/raildays/chat",
		component: lazy(() => import("Ui/Raildays/RaildaysChatView.js")),
		title: "Raildays",
		hideWhenAdminAuthed: true
	},
	{
		uri: "/raildays/notifications",
		component: lazy(() => import("Ui/Raildays/RaildaysNotificationsView.js")),
		title: "Raildays",
		hideWhenAdminAuthed: true
	},
	{
		uri: "/raildays/rosters",
		component: lazy(() => import("Ui/Raildays/RaildaysRostersView.js")),
		title: "Raildays"
	},
	{
		uri: "/raildays",
		component: lazy(() => import("Ui/Raildays/RaildaysView.js")),
		public: true,
		title: "Raildays"
	},
	{
		uris: ["/raildays/news/create"],
		component: lazy(() => import("News/raildays/NewsCreateEdit.js")),
		title: "Create Raildays News Story",
		permission: [513, 0]
	},
	{
		uris: ["/raildays/news"],
		component: lazy(() => import("News/raildays/News.js")),
		title: "Raildays News",
		permission: [513, 0]
	},
	{
		uris: ["/raildays/news/:id"],
		component: lazy(() => import("News/raildays/NewsStory.js")),
		title: "Raildays News Story",
		permission: [513, 0]
	},
	{
		uris: ["/raildays/news/:id/edit"],
		component: lazy(() => import("News/raildays/NewsCreateEdit.js")),
		title: "Edit Raildays News Story",
		permission: [513, 0]
	},
	{
		uri: "/rb/admin/types/:id",
		component: lazy(() => import("Bookables/Admin/BookablesUnitTypeFormView.js")),
		title: "Bookable Unit Type",
		permission: [469, 0]
	},
	{
		uri: "/rb/admin/units/:id",
		component: lazy(() => import("Bookables/Admin/BookablesUnitFormView.js")),
		title: "Bookable Unit",
		permission: [469, 0]
	},
	{
		uris: ["/rb/admin", "/rb/admin/types", "/rb/admin/units", "/rb/admin/general"],
		component: lazy(() => import("Bookables/Admin/BookablesAdminView.js")),
		title: "Accommodation Booking System Settings",
		permission: [469, 0]
	},
	{
		uri: "/rb/bookings",
		component: lazy(() => import("Bookables/BookablesView.js")),
		title: "Accommodation Bookings",
		permission: [473, -1]
	},
	{
		uris: ["/rb", "/rb/chart", "/rb/bookings/mine", "/rb/bookings/mine/authored"],
		component: lazy(() => import("Bookables/BookablesView.js")),
		title: "Accommodation Bookings",
		permission: [470, -1]
	},
	{
		uri: "/rb/bookings/:Id",
		component: lazy(() => import("Bookables/Bookings/BookablesBookingView.js")),
		title: "Booking Details"
	},
	{
		uris: ["/roles/:Id", "/departments/:departmentId/roles/new"],
		component: lazy(() => import("Roles/RoleEditor/RoleEditorView.js")),
		title: "Role Editor"
	},
	{
		uri: "/sales/:Id/items/credit",
		component: lazy(() => import("Orders/CreditNotes/IssueOrderItemsCreditNoteView.js")),
		title: "Issue Credit Note",
		permission: [467, 0]
	},
	{
		uri: "/user/account/link",
		component: lazy(() => import("UserManagement/JoinRequests/JoinRequestView.js")),
		title: "Link Accounts",
		hideWhenAdminAuthed: true
	},
	{
		uri: "/user/account",
		component: lazy(() => import("UserManagement/HopsAccountView.js")),
		title: "My HOPS Account"
	},
	{
		uris: [
			"/users/new",
			"/user_admin_add.php"
		],
		component: lazy(() => import("UserManagement/AddUser/AddUserView.js")),
		title: "Add New User"
	},
	{
		uris: [
			"/user/details/confirmation",
			"/users/:user/details/confirmation"
		],
		component: lazy(() => import("UserManagement/UserDetailsConfirmationView.js")),
		title: "Confirming Details"
	},
	{
		uris: [
			"/user/:tab?",
			"/user/:tab/confirm",
			"/users/:user/:tab?",
			"/users/:user/:tab/confirm",
			"/user_admin_view.php",
			"/user_details_view.php"
		],
		component: lazy(() => import("UserManagement/UserDetailsView.js")),
		title: "User Details"
	},
	{
		uris: [
			"/api/auth/admin",
			"/login/admin",
			"/admin_login_menu.php"
		],
		component: lazy(() => import("Auth/AdminLoginView.js")),
		title: "Admin Login"
	},
	{
		uris: ["/mem/:tab?", "/mem.php", "/members.php", "/members_statistics.php", "/members_sales.php"],
		component: lazy(() => import("Memberships/MembershipView.js")),
		title: "Memberships",
		permission: [193, 0]
	},
	{
		uris: ["/mem/edit", "/mem/edit.php"],
		component: lazy(() => import("Memberships/MembershipViewEdit.js")),
		title: "Memberships"
	},
	{
		uris: ["/mem/renew", "/mem/renew.php"],
		component: lazy(() => import("Memberships/MembershipViewRenew.js")),
		title: "Memberships"
	},
	{
		uris: ["/mem/details/:membershipId", "/mem/details/:membershipId/:back", "/mem/details.php"],
		component: lazy(() => import("Memberships/MembershipViewDetails.js")),
		title: "Memberships"
	},
	{
		uris: ["/mem/system", "/mem/system.php"],
		component: lazy(() => import("Memberships/MembershipSiteSettings/MembershipSystemSettingsView.js")),
		title: "Memberships"
	},
	{
		uris: ["/mem/system/type/form", "/mem/system/type/form/:id"],
		component: lazy(() => import("Memberships/MembershipSiteSettings/MembershipSiteSettingsTypeEditNew.js")),
		title: "Memberships"
	},
	{
		uris: ["/mem/add", "/mem/add/:action/:userId"],
		component: lazy(() => import("Memberships/MembershipSiteSettings/MembershipSiteSettingsAddNew.js")),
		title: "Memberships"
	},
	{
		uris: [
			"/mem/system/:tab?"
		],
		component: lazy(() => import("Memberships/MembershipSiteSettings/MembershipSystemSettingsView.js")),
		title: "Memberships"
	},
	{
		uris: ["/spotreport", "/near_miss_report.php"],
		component: lazy(() => import("SpotReport/SpotReportView.js")),
		title: "Spot Report"
	},
	{
		uri: "/spotreport/detail/:id/:view",
		component: lazy(() => import("SpotReport/SpotReportDetailView.js")),
		title: "Spot Report Detail"
	},
	{
		uris: [
			"/spotreport/:tab?"
		],
		component: lazy(() => import("SpotReport/SpotReportView.js")),
		title: "Spot Report"
	},
	{
		uri: "/shares/:id/dealwith",
		component: lazy(() => import("Shares/ShareDealingDealWith.js")),
		title: "Shares Deal With",
		permission: [502, -1]
	},
	{
		uri: "/sharedealing/order_item_lookup",
		component: lazy(() => import("Shares/ShareDealingOrderItemLookup.js")),
		title: "Share Dealing Order Item Lookup",
		permission: [502, -1]
	},
	{
		uris: ["/sharedealing", "/sharedealing/:id", "/sharedealing/:id/:mode"],
		component: lazy(() => import("Shares/ShareDealing.js")),
		title: "Share Dealing",
		permission: [502, -1]
	},
	{
		uri: "/stripe/connect/status",
		component: lazy(() => import("StripeConnect/StripeConnectStateView.js")),
		title: "Stripe Connect",
		permission: [498, 0]
	},
	{
		uri: "/verify/email",
		component: lazy(() => import("EmailValidation/EmailValidationView.js")),
		title: "Email Validation",
		public: true
	},
	{
		uris: ["/help/admin"],
		component: lazy(() => import("Help/HelpAdmin.js")),
		title: "HOPS Support Admin",
		permission: [150, 0]
	},
	{
		uris: ["/help/create"],
		component: lazy(() => import("Help/HelpCreateEdit.js")),
		title: "Create HOPS Support Article",
		permission: [150, 0]
	},
	{
		uris: ["/help", "/help/:slug"],
		component: lazy(() => import("Help/HelpArticle.js")),
		title: "HOPS Support",
		public: true
	},
	{
		uris: ["/help/:slug/edit"],
		component: lazy(() => import("Help/HelpCreateEdit.js")),
		title: "Edit HOPS Support Article",
		permission: [150, 0]
	},
	{
		uris: ["/roster/:id"],
		component: lazy(() => import("Rosters/RosterPeriod/RosterPeriodView.js")),
		permission: [43, -1]
	},
	{
		uri: "/:uri",
		component: lazy(() => import("Legacy/LegacyView.js")),
		inexact: true,
		public: true
	},
	{
		uri: "/",
		component: lazy(() => import("Legacy/LegacyView.js")),
		public: true,
		publicOnly: true
	},
	{
		uri: "/",
		component: lazy(() => import("Home/HomeView.js")),
		title: "Home"
	}
];
